// pure CSS toggle switch, adapted from https://codepen.io/kowlor/pen/ByavWB

$sw-height: 16px;
$sw-width: 32px;
$radius: 8px;

input {
  &.sw {
    opacity: 0;
    position: absolute;
    left: -9999px;
    & + label {
      user-select: none;
      transition: 0.2s ease;
      display: inline-block;
      height: $sw-height;
      width: $sw-width;
      position: relative;
      background: #6b6b6b;
      // box-shadow: inset 0 0 0px 1px #e4e4e4;
      border-radius: $radius;
      &:before {
        content: "";
        position: absolute;
        display: block;
        height: $sw-height;
        width: $sw-height;
        top: 0;
        left: 0;
        border-radius: $radius;
        background: rgba(76, 217, 100, 0);
        transition: 0.2s cubic-bezier(0.24, 0, 0.5, 1);
      }

      /* White toggle */
      &:after {
        content: "";
        position: absolute;
        display: block;
        height: $sw-height - 4;
        width: $sw-height - 4;
        top: 50%;
        margin-top: -6px;
        left: 2px;
        border-radius: $radius - 1;
        background: rgba(255, 255, 255, 0.75);
        box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1),
          0 4px 0px 0 hsla(0, 0%, 0%, 0.04), 0 4px 9px hsla(0, 0%, 0%, 0.13),
          0 3px 3px hsla(0, 0%, 0%, 0.05);
        transition: 0.25s cubic-bezier(0.54, 1.6, 0.5, 1);
      }
      span {
        white-space: nowrap;
        height: $sw-height;
        line-height: $sw-height;
        margin-left: $sw-width;
        padding-left: 5px;
        position: absolute;
        height: -5px;
      }
    }
    &:checked {
      & + label:before {
        width: $sw-width;
        background: #3993FF;
        transition: width 0.2s cubic-bezier(0, 0, 0, 0.1) !important;
      }

      & + label:after {
        left: $sw-width - $sw-height + 2;
      }
    }
  }
}
