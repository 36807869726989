table.is-loading {
  filter: blur(5px);
  animation: blurload 2s infinite ease-in-out;
  pointer-events: none;
}

@keyframes blurload {
  0% {
    filter: blur(5px);
  }

  50% {
    filter: blur(10px);
  }

  100% {
    filter: blur(5px);
  }
}

.fit-to-content {
  width: 1%;
}

div.forensics-string {
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  word-break: break-all;
  border-bottom: 1px dashed #666;
  font-family: Splunk Data Mono;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
}

div.forensics-string > pre > code {
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  word-break: break-all;
  font-family: Splunk Data Mono;
}

span.forensics-string {
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  word-break: break-all;
  font-family: Splunk Data Mono;
}

div.hexviewer > div.forensics-string {
  border-bottom: none !important;
}
div.textviewer > div.forensics-string {
  border-bottom: none !important;
}

.email-body {
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  word-break: break-all;
  font-family: Splunk Data Mono;
}

.email-header {
  white-space: pre-wrap;
  overflow-wrap: anywhere;
  word-break: break-all;
  font-family: Splunk Data Mono;
}

div.detection-detail {
  word-break: break-all;
}

td.forensic-value {
  word-break: break-all;
}

.nowrap {
  white-space: nowrap;
}

.wrap-anywhere {
  overflow-wrap: anywhere;
}

.threat-name {
  word-break: break-all;
  direction: ltr;
  unicode-bidi: bidi-override;
}

.expandable-control {
  cursor: pointer;
  align-items: center;
  display: inline-flex;
  padding: 0.2em 0.3em;
  border-radius: 3px;
}

.expandable-control:hover {
  background-color: #f9f9f9;
}

.expandable-control .icon {
  width: 1.5em;
}

.expandable-content {
  margin-left: 2.5em;
}

.expandable-content.fullwidth {
  width: 100%;
}

.resource-name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

p.panel-heading.resource-name {
  padding-left: 0.65em;
}

button.link {
  border: 0px;
  cursor: pointer;
  background-color: white;
}

.multiline-string:hover {
  cursor: pointer;
  background-color: #f2f2f2;
}

.multiline-string.collapsed {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.has-ellipsed-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@media print {
  .is-hidden-print {
    display: none !important;
  }

  .print-block {
    display: block !important;
  }

  .print-fullwidth {
    width: 100% !important;
  }

  .expandable-control .icon {
    display: none !important;
  }
}

.disable-selection {
  user-select: none;
}

.nestedDetections {
  position: relative;
  left: -1.5em;
}
