.expandable-box {
  width: 100%;
  margin: 0.5rem 0 1.5rem 0;
  border-radius: 4px;

  position: relative;
}

.expandable-box .box-title {
  border-radius: 3px 3px 0px 0px;
  font-weight: bold;
  user-select: none;
  padding: 0.25rem 1rem;
}

.expandable-box .expandable-box {
  border: 0;
}

.expandable-box .box-title .visibility-icon {
  float: right;
}

.expandable-box .box-title:hover {
  cursor: pointer;
}

.expandable-box .box-content {
  overflow: hidden;
  padding: 1rem;
}

.expandable-box .box-content.hidden {
  display: none;
}

.expandable-box .box-expand {
  text-align: center;
  user-select: none;
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.expandable-box .box-expand:hover {
  cursor: pointer;
}
