treelist,
.treelist ul,
.treelist li {
  position: relative;
}

.treelist ul {
  padding-left: 24px;
}

.treelist div.resource {
  display: flex;
  align-items: center;
}

.treelist .resource .resource-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.treelist .resource .resource-title:hover {
  cursor: pointer;
  background-color: #f2f2f2;
}

.treelist li::before,
.treelist li::after {
  content: "";
  position: absolute;
  left: -12px;
}

.treelist li::before {
  border-top: 1px solid #bbb;
  top: 9px;
  width: 8px;
  height: 0;
}

.treelist li::after {
  border-left: 1px solid #bbb;
  height: 100%;
  width: 0px;
  top: 2px;
}

.treelist li.root::before,
li.root::after {
  border: none;
}

.treelist ul.root {
  padding-left: 16px;
}

.treelist ul > li:last-child::after {
  height: 8px;
}

.treelist .added-because {
  margin-right: 4px;
  font-style: italic;
  font-size: 0.9rem;
}
