@charset "utf-8";

/* Splunk Data Sans Regular */
@font-face {
  font-family: 'Splunk Data Sans';
  src: url('splunkdatasans-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// /* Splunk Data Sans Medium */
// @font-face {
//   font-family: 'Splunk Data Sans';
//   src: url('splunkdatasans-medium.woff') format('woff');
//   font-weight: '500'; 
//   font-style: normal;
// }

// /* Splunk Data Sans Bold */
// @font-face {
//   font-family: 'Splunk Data Sans';
//   src: url('splunkdatasans-bold.woff') format('woff');
//   font-weight: bold;
//   font-style: normal;
// }

/* Splunk Data Sans Mono */
@font-face {
  font-family: 'Splunk Data Mono';
  src: url('splunkdatasansmono-regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

$family-sans-serif: "Splunk Data Sans", sans-serif;
$family-monospace: "Splunk Data Mono", monospace;
$body-size: 14px;

// splunk theme colors
$backgroundColorPopup: #27292e;
$backgroundColorSection: #1a1c20;
$backgroundColorSidebar: #0b0c0e;
$backgroundColorPage: #111215;
$backgroundColorNavigation: #08090a;
$backgroundColorFloating: #ffffff;
$backgroundColorDialog: #1e2024;
$backgroundColorScrim: rgba(0, 0, 0, 0.8);

$contentColorActive: #fafafa;
$contentColorDefault: #b5b5b5;
$contentColorDisabled: #6b6b6b;
$contentColorInverted: #000000;
$contentColorMuted: #909090;

$interactiveColorPrimary: #3993FF;
$interactiveColorBorder: rgba(255, 255, 255, 0.5);
$interactiveColorBorderHover: rgba(255, 255, 255, 0.7);
$interactiveColorBorderDisabled: rgba(255, 255, 255, 0.30);
$interactiveColorOverlaySelected: rgba(255, 255, 255, 0.1);
$interactiveColorOverlayHover: rgba(255, 255, 255, 0.05);
$interactiveColorOverlayActive: rgba(0, 0, 0, 0.2);
$interactiveColorOverlayDrag: rgba(57, 147, 255, 0.16);
$interactiveColorBackground: #272a2f;
$interactiveColorBackgroundDisabled: rgba(255, 255, 255, 0.15);

$black: #000000;
$neutral100: #33343b;
$neutral200: #43454b;
$neutral300: #505158;
$neutral400: #818285;
$neutral500: #acacad;
$white: #ffffff;

$accentColorPositive: #85f415;
$accentColorWarning: #f49106;
$accentColorAlert: #f0581f;
$accentColorNegative: #ff4242;

$statusColorInfo: #61cafa;
$statusColorNormal: #85f415;
$statusColorLow: #2cbda3;
$statusColorMedium: #f49106;
$statusColorHigh: #ff4242;
$statusColorCritical: #ff3361;

$syntaxBlue: #6cd0f0;
$syntaxBrown: #fccf87;
$syntaxGray: #7d7d7d;
$syntaxGreen: #cef06c;
$syntaxOrange: #f7933f;
$syntaxPink: #f494e5;
$syntaxPurple: #a870ef;
$syntaxRed: #e85b79;
$syntaxTeal: #45d4ba;

// bulma overrides
$red: $accentColorAlert;
$red-dark: findDarkColor($red);
$green: $accentColorPositive;
$green-dark: findDarkColor($green);
$yellow: $accentColorWarning;
$yellow-lighter: findLightColor($yellow);
$yellow-dark: findDarkColor($yellow);
$grey: $neutral400;
$grey-lighter: $neutral500;
$grey-dark: $neutral400;
$grey-darker: $neutral100;
$danger-invert: black;

$background: $backgroundColorScrim;
$scheme-main: $backgroundColorPage;
$scheme-main-bis: $background;
$primary: $interactiveColorPrimary;
$link: $interactiveColorPrimary;
$link-hover: $link;
$link-focus: mix($link, $white, 75%);
$link-invert: $backgroundColorPage;
$info: $interactiveColorPrimary;
$link-visited: mix($link, $neutral100, 60%);
$text: $contentColorDefault;
$text-light: $contentColorActive;
$text-strong: $contentColorActive;
$text-dark: $contentColorMuted;
$light: $backgroundColorPopup;
$warning-dark: $syntaxOrange;
$info-light: $syntaxBlue;

$success: $statusColorLow;
$success-invert: $black;
$danger: $statusColorCritical;
$warning-invert: $black;

// specific bulma components
$tag-background-color: $interactiveColorBackground;
$tag-color: $text-strong;
$tabs-link-hover-border-bottom-color: $contentColorDisabled;
$tabs-link-active-border-bottom-color: $text-light;
$tabs-link-active-color: $text-light;
$tabs-border-bottom-color: $neutral100;
$label-color: $text;
$input-arrow: $text;
$input-color: $text;
$navbar-background-color: $background;
$navbar-item-hover-background-color: $interactiveColorOverlaySelected;
$navbar-item-active-color: $contentColorDefault;
$navbar-height: 4rem;
$panel-radius: 0px;
$panel-heading-padding: 0.3em 1em;
$panel-heading-size: 1.1em;
$panel-heading-weight: 500;
$panel-heading-background-color: $backgroundColorPopup;
$panel-block-active-color: $text-light;
$panel-block-active-icon-color: $text-light;
$panel-block-hover-background-color: $interactiveColorOverlayHover;
$button-text-decoration: "none";
$border: $contentColorDisabled;
$pagination-border-color: $link;
$card-footer-border-top: 1px solid $grey-darker;
$card-shadow: 0 0px 0 1px $grey-darker;
$box-shadow: none;
$table-color: $text-strong;
$table-head-cell-color: $contentColorDefault;
$table-cell-heading-color: $contentColorDefault;
$table-cell-border: 1px solid $interactiveColorBorderDisabled;
$table-head-cell-border-width: 0 0 1px;
$table-row-hover-background-color: $interactiveColorOverlayHover;
$button-text-hover-background-color: $interactiveColorOverlayHover;
$button-focus-color: none;

.panel-block.is-active {
  background-color: $neutral200;
}

.panel-block.is-active:hover {
  background-color: $neutral300;
}

.panel-block:hover {
  color: $text-strong;
}

.panel {
  border: 1px solid $neutral200;
  border-radius: 4px !important;
}

$divider-color: $text-light;

$switch-background: $grey-darker;

$progress-bar-background-color: $background;

$cy-tooltip-background-color: $backgroundColorSection;

$dropdown-content-background-color: $backgroundColorPopup;
$dropdown-item-hover-background-color: $interactiveColorOverlayHover;
$dropdown-item-hover-color: $text-strong;

// twinwave component styling

$hex-null: $syntaxGray;
$hex-printable: $syntaxBlue;
$hex-ws: $syntaxTeal;
$hex-ascii: $syntaxRed;
$hex-nonascii: $syntaxOrange;

@import "node_modules/bulma/bulma.sass";

@import "node_modules/bulma-helpers/bulma-helpers";
@import "node_modules/bulma-extensions/bulma-switch/src/sass/index.sass";
@import "node_modules/bulma-extensions/bulma-divider/dist/css/bulma-divider.sass";
@import "node_modules/@cypress/react-tooltip/dist/tooltip.scss";

.has-text-default {
  color: $contentColorDefault;
}

.message.is-warning {
  background-color: $backgroundColorPopup;
}

.message.is-warning .message-body {
  color: $text-strong;
}

.is-divider-vertical[data-content]::after {
  background-color: $background;
}

.expandable-control:hover {
  background-color: rgba(white, 0.1);
}

.multiline-string:hover {
  background-color: $background;
}

.treelist .resource .resource-title {
  height: 1.6rem;
  line-height: 1.6rem;
  border-radius: 5px;
  color: $text-strong
}

.treelist .resource .resource-title:hover {
  background-color: $interactiveColorOverlayHover;
  text-decoration: underline;
}

.treelist .resource.selected .resource-title {
  background-color: $interactiveColorOverlaySelected;
  color: $text-strong;
  padding-left: 5px;
  border: 1px solid $interactiveColorBorderHover;
}

a.joblink:visited {
  color: $link-visited;
}

a.joblink:hover {
  color: $link-hover;
  text-decoration: underline;
}

mark {
  color: $black;
  background-color: $syntaxBrown;
}

.modal-close::before,
.modal-close::after {
  background-color: invert($background);
}

.modal header button.delete {
  background-color: rgba(invert($background), 0.75);
}

.hexviewer pre {
  background-color: $backgroundColorPage;
}

.hex-null {
  color: $hex-null;
}

.hex-printable {
  color: $hex-printable;
}

.hex-ws {
  color: $hex-ws;
}

.hex-ascii {
  color: $hex-ascii;
}

.hex-nonascii {
  color: $hex-nonascii;
}

.hex-header {
  color: $grey-lighter;
  font-weight: 800;
}

.is-twblue-light {
  color: $link-active;
}

.underline-warning {
  border-bottom: 1px solid $yellow;
}

.underline-danger {
  border-bottom: 1px solid $red;
}

.expandable-box {
  border: 01px solid $neutral200;
}

.expandable-box.is-warning {
  border: 01px solid $yellow-lighter;
}

.expandable-box .box-title {
  background-color: $neutral200;
  color: $text-strong;
  font-weight: normal;
}

.expandable-box.is-warning .box-title {
  background-color: $yellow-lighter;
  color: $background;
}

.expandable-box.is-warning .box-title .visibility-icon {
  color: $background;
}

.expandable-box .box-title .visibility-icon {
  color: $text-strong;
}

.expandable-box .box-expand {
  background-image: linear-gradient(
    rgba($background, 0.25),
    rgba($background, 1)
  );
  backdrop-filter: blur(1px);
}

.expandable-box .box-expand:hover {
  background-image: none;
  background-color: rgba($background, 0.95);
}

.expandable-box .expandable-box .box-title {
  border-bottom: 1px solid $interactiveColorBorderDisabled;
  background-color: inherit;
}

.copyable-data button {
  color: $text;
}

.copyable-data button:hover {
  background-color: $interactiveColorOverlayHover;
}

.copyable-data button:active {
  color: $link;
}

.button.is-text {
  color: $text-strong;
}

.tag a {
  color: $text;
}

a.tw-label:hover {
  background-color: rgba(white, 0.15);
  color: $link;
  text-decoration: none;
}

.surfer-meter {
  width: 300px;
  height: 20px;
  background-color: findDarkColor($link);
  display: flex;
}

.surfer-meter > div {
  height: 100%;
  background-color: $link;
  animation: loadup 10s infinite ease-in-out;
}

@keyframes loadup {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.surfer-loader {
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $background;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.surfer-spinner {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
  text-align: center;

  animation: sk-rotate 2s infinite linear;
}

.surfer-dot1,
.surfer-dot2 {
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: $link;
  border-radius: 100%;

  animation: sk-bounce 2s infinite ease-in-out;
}

.surfer-dot2 {
  top: auto;
  bottom: 0;
  background-color: findDarkColor($link);
  animation-delay: -1s;
}

@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}

span.icon.beta {
  color: $yellow-lighter;
  position: relative;
  top: -1px;
}

// div.tabs.with-dividers
//   > ul
//   > li:not(.is-active
//     + li):not(:first-of-type):not(.is-active):not(:only-of-type)::before {
//   content: "";
//   width: 1px;
//   height: 1.5em;
//   background-color: darken($grey, 20);
//   position: absolute;
//   top: 24px;
// }

span.star-rating {
  color: $grey-lighter;

  &.is-selected {
    color: $yellow-lighter;
  }
}

.detailGroup:not(:first-child) {
  padding-top: 0.75em;
}

.detailGroup:not(:last-child) {
  border-bottom: 1px solid $grey-darker;
  padding-bottom: 0.75em;
}

.source-code {
  color: $text;
  background-color: $backgroundColorPage;
  padding: 0;
  font-size: 1.0rem;
}

span.react-syntax-highlighter-line-number {
  color: $text-dark !important;
  min-width: 3rem !important;
}

.toggle-button.is-selected {
  color: $link;
  border: none;
}

.toggle-button.is-not-selected {  
  border: none;

  &:hover {
    background-color: $light;
  }
}

.toggle-button:focus:not(:focus-visible) {
  outline: 0 !important;
  box-shadow: none !important;
}

:root {
  --toastify-color-warning: #{$statusColorMedium};
  --toastify-text-color-warning: #{black};
  --toastify-color-success: #{$statusColorLow};
  --toastify-text-color-success: #{black};
  --toastify-color-error: #{$statusColorCritical};
  --toastify-text-color-error: #{black};
}

ol.lined-list > li {
  border-bottom: 1px solid $border;
  padding-left: 1rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
}

ol.lined-list > li:last-child {
  border-bottom: none;
}

.logo-text {
  color: $text-light;
  font-weight: 500;
  font-size: 18px;
  margin-left: 0.5rem;
  margin-right: 2rem;
}

.is-twinwave-multiselect {
    width: 100%;
  
    .dropdown-menu,
    .dropdown-trigger {
      width: 100%;
    }
  
    .dropdown-trigger > button {
      width: 100%;
      display: block;
      text-align: left;
  
      &::after {
        @extend %arrow;
        border-color: $input-arrow;
        right: 1.125em;
        z-index: 4;
      }
    }
  
    .dropdown-content > div {
      overflow: hidden;
    }
}
