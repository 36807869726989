.copyable-data {
  display: flex;
  overflow: hidden;
}

.copyable-data .data {
  overflow: hidden;
  text-overflow: ellipsis;
}

.copyable-data-inline {
  display: inline-flex;
  overflow: hidden;
}
.copyable-data-inline .data {
  display: inline-flex;
  overflow: hidden;
  text-overflow: ellipsis;
}

.copyable-data .copy-button, .copyable-data-inline .copy-button {
  transition: opacity 0.4s, width 0.4s;
  transition-delay: 0.4s;
  width: 0px;
  opacity: 0;
  margin-left: 5px;
}

.copyable-data:hover .copy-button, .copyable-data-inline:hover .copy-button {
  width: 3rem;
  opacity: 1;
}

.copyable-data button, .copyable-data-inline button {
  cursor: pointer;
  border: none;
  border-radius: 3px;
  background-color: transparent;
  color: white;
  padding: 3px 6px;
}

.copyable-data button:focus, .copyable-data-inline button:focus {
  outline: none;
}

.copyable-data button:active, .copyable-data-inline button:active {
  border: none;
  color: blue;
}
