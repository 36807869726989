table.resource-summary td {
  padding: 0.1em 0.3em;
  border-style: hidden !important;
}

table.resource-summary th {
  padding: 0.1em 0.8em 0em 0.3em;
  border-style: hidden !important;
  white-space: nowrap;
  width: 1%;
}

table.resource-summary .threat-name {
  text-decoration: underline;
  font-size: 1.1rem;
}
